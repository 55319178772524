<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="10" md="9" lg="7" class="mx-auto">
        <v-card class="sd pa-8 my-12">
          <span>
            <v-btn @click="showPM = true" class="tg sd">发送私信</v-btn>
          </span>
          <br /><br />
          <span v-if="message">
            <span v-for="i in message" :key="i">
              <span v-html="i.message"></span>
              <v-btn
                icon="mdi-delete"
                class="ml-2 float-right mt-n3"
                color="#0000"
                v-on:click="delmsg(i.id)"
                size="small"
                variant="flat"
              >
              </v-btn>
              <span class="text-h7 text-medium-emphasis ml-2 float-right">
                <span>{{ convertTimestamp(i.time) }}</span>
              </span>
              <br /><br />
            </span>
          </span>
          <div v-else>
            <v-progress-circular
              color="primary"
              indeterminate
            ></v-progress-circular>
            正在载入消息……
          </div>
          <div class="text-center my-3" v-if="msgtotal > 0">
            <s-pagination
              v-model="msgpage"
              :length="Math.ceil(msgtotal / 20)"
              :total-visible="7"
            >
            </s-pagination>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="showPM">
      <v-dialog v-model="showPM" max-width="600" min-height="300">
        <v-card class="pa-4">
          <v-text-field label="对方id" v-model="id"> </v-text-field>
          <v-text-field label="内容" v-model="text"> </v-text-field>
          <!-- <v-card-actions> -->
          <v-spacer></v-spacer>
          <v-btn class="my-2 tg sd" @click="sendpm()">发送 </v-btn>
          <!-- </v-card-actions> -->
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script setup>
import { ref, watch } from "vue";
import SPagination from "./SPagination.vue";
var showPM = ref(false),
  id = ref(""),
  text = ref(""),
  msgpage = ref(1),
  message = ref(null),
  msgtotal = ref(0),
  convertTimestamp = window.convertTimestamp;
function sendpm() {
  window.post("user/private", { id: id.value, text: text.value }).then(() => {
    showPM.value = false;
  });
}

function getMessgae() {
  message.value = null;
  window
    .get("user/message", {
      l: 20,
      o: 20 * (msgpage.value - 1),
    })
    .then((m) => {
      message.value = m.data.map(d=>{
        d.message=window.DOMPurify.sanitize(d.message)
        return d
      });
      msgtotal.value = m.num;
    });
}
watch(msgpage, () => getMessgae());
function delmsg(id) {
  message.value = null;
  window.post("user/message/delete", { id }).then(() => getMessgae());
}
getMessgae();
window.setTitle('消息')
</script>