<template>
  <v-container>
    <v-row>
      <v-col class="mx-auto">
        <v-card class="mx-auto pa-8 my-12">
          <v-file-input
            accept="accept='.js'"
            label="扩展文件"
            max-length="100"
            id="extf"
          ></v-file-input>
          <v-text-field label="扩展名称" id="extn"></v-text-field>
          <v-text-field
            label="扩展id(文件getinfo里的id)"
            id="extid"
          ></v-text-field>
          <v-text-field label="封面url(链接)" id="image"></v-text-field>
          <v-text-field label="作者名称" id="extan"></v-text-field>
          <v-btn elevation="2" @click="ue">点击上传</v-btn>
          <br />传好后即刻生效，请自行审核，避免含有影响40code安全的内容。<br />
          更新时可以只填写扩展id和要更改的部分</v-card
        ></v-col
      >
    </v-row>
  </v-container>
</template>
<script setup>
var ue = () => {
  // 获取文件输入框中的文件
  var $ = window.$;
  if (!$("#extid").val()) {
    return window.alert("请输入扩展id");
  }
  var fileInput = $("#extf")[0];
  var file = fileInput.files[0];
  // 构建一个查询字符串，包含需要作为URL参数的值
  var queryString = $.param({
    token: window.getCookie("token"),
    name: $("#extn").val(),
    author: $("#extan").val(),
    id: $("#extid").val(),
    image: $("#image").val(),
  });

  // 创建一个 FormData 对象并添加文件
  var formData;
  if (file) {
    formData = new FormData();
    formData.append("file", file);
  }

  // 发起带有 URL 参数的 POST 请求
  $.ajax({
    url:
      `${window.apihost}work/upload-ext?` +
      queryString,
    type: "POST",
    data: formData,
    processData: false, // 告诉jQuery不要去处理发送的数据
    contentType: false, // 告诉jQuery不要去设置Content-Type请求头
    success: function (response) {
      // 请求成功后的回调函数
      console.log("文件上传成功:", response);
      window.dialog(response.msg);
    },
    error: function (xhr, status, error) {
      // 请求失败后的回调函数
      console.error("文件上传失败:", error);
    },
  });
};
</script>