<template>
  <v-container>
    <v-row>
      <v-col class="mx-auto" cols="12" md="6">
        <v-card class="pa-8 my-12" v-if="!info.id">作品信息正在加载中</v-card>
        <v-card class="pa-8 my-12" v-else-if="info.isauthor">
          <v-text-field label="作品名" v-model="info.name"></v-text-field>
          <MarkdownInput label="作品介绍" v-model="info.introduce" class="my-2" />
          <v-checkbox v-model="info.publish" :label="`发布 `"></v-checkbox>
          <v-checkbox v-model="info.opensource" :label="`开源 `"></v-checkbox>
          <ImageUpload :host="host" v-model="info.image" bl="4:3"/>
          <div class="mx-auto">
            <v-btn class="ma-2 tg sd" v-on:click="update()">更新</v-btn>
            <v-btn class="ma-2 tg sd ml-4" v-on:click="back()">返回</v-btn>
          </div>
        </v-card>
        <v-card class="pa-8 my-12" v-else>无权限查看当前作品</v-card></v-col>
    </v-row>
  </v-container>
</template>
<script setup>
import { ref, defineProps } from "vue";
import MarkdownInput from './MarkdownInput.vue';
import ImageUpload from "./ImageUpload.vue";
defineProps(["host", "myInfo"]);
const getQueryString = window.getQueryString;
var info = ref({})

var update = function () {
  window.post("work/info/update", info.value);
};
var back=()=>{
  location.href = '#page=work&id=' + info.value.id
}
window
  .get("work/info", {
    id: getQueryString("id"),
    sha: getQueryString("sha"),
    etime: getQueryString("etime"),
  })
  .then((d) => {
    d=d.data
    d.publish=!!d.publish
    d.opensource=!!d.opensource
    info.value = d

  });

window.setTitle('作品信息')
</script>