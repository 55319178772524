<template>
  <div v-if="comment" class="my-5 comment-view">
    <div v-for="(i, index) in sortedComments" class="my-6" :key="i">
      <div v-for="j in comment.user[i.fromuser.toString()]" class="mt-2" :key="j">
        <div>
          <v-row no-gutters>
            <span style="flex: 0 0 5px"></span>
            <span style="flex: 0 0 10px" class="mt-1">
              <a :href="'#page=user&id=' + i.fromuser">
                <span :class="'avatar2 id' + i.fromuser"></span>
                <s-avatar :host="host" :head="j.head" size="40" :headwears="headwears[i.fromuser]"></s-avatar>
              </a>
            </span>
            <span style="flex: 0 0 10px"></span>
            <span style="flex: 0 0 calc(100% - 60px)">
              <v-row no-gutters>
                <v-col cols="12">
                  <a :href="'#page=user&id=' + i.fromuser">
                    {{ j.nickname }}
                  </a>
                  <a :href="`#page=studio&id=${comment.studio[j.studio].id}`" v-if="comment.studio[j.studio]"
                    class="pl-2">
                    <v-btn style="text-transform: none !important" :color="comment.studio[j.studio].color || 'green'"
                      class="sd tg" size="small">
                      <span style="color: white">{{
                        comment.studio[j.studio].name
                      }}</span>
                    </v-btn>
                  </a>
                </v-col>
                <v-col cols="12">
                  <MarkdownContext class="pm" style="max-height: 500px; word-wrap: break-word" :text="i.comment" />
                  <comments-operation
                    :time="i.time"
                    @showReply="showReply(i.id, null, i)"
                    @deleteComment="deleteComment(i)"
                    @pinComment="pinComment(i)"      
                    @unpinComment="unpinComment(i)"   
                    :ip_format="i.ip_format"
                    :showDelete="shouldShowDeleteButton(i)"
                    :showPin="shouldShowPinButton(i)"      
                    :isPinned="!!i.is_pinned"           
                  />

                  <br />
                  <span v-if="myInfo && replyId == i.id">
                    <br />
                    <s-c2 :id="id" :type="type" :host="host" :myInfo="myInfo" :reply="i.id" :touid="rid" :toname="comment.user[rid] && comment.user[rid][0].nickname
                      " class="mt" @reget="reget()">
                    </s-c2>
                  </span>
                </v-col>
              </v-row>
            </span>
          </v-row>
        </div>
        <div v-if="i.replynum" class="mt-n5">
          <div v-for="(k, num) in comment.reply[i.id.toString()]" :key="num">
            <div v-if="num < 2 || showed.indexOf(index) !== -1">
              <div v-for="j in comment.user[k.fromuser.toString()]" class="mt-4" :key="j">
                <v-row no-gutters>
                  <span style="flex: 0 0 50px"></span>
                  <span style="flex: 0 0 10px" class="mt-1">
                    <a :href="'#page=user&id=' + j.id">
                      <span :class="'avatar2 id' + j.id"></span>
                      <s-avatar :host="host" :head="j.head" size="40" :headwears="headwears[j.id]"></s-avatar>
                    </a>
                  </span>
                  <span style="flex: 0 0 15px"></span>
                  <span style="flex: 0 0 calc(100% - 110px)">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <a :href="'#page=user&id=' + k.fromuser">
                          {{ j.nickname }}
                        </a>
                        <a :href="`#page=studio&id=${comment.studio[j.studio].id
                          }`" v-if="comment.studio[j.studio]" class="pl-2">
                          <v-btn style="text-transform: none !important"
                            :color="comment.studio[j.studio].color || 'green'" class="sd tg" size="small">
                            <span style="color: white">{{
                              comment.studio[j.studio].name
                            }}</span>
                          </v-btn>
                        </a>
                      </v-col>
                      <v-col cols="12">
                        <MarkdownContext class="pm" style="max-height: 500px; word-wrap: break-word" :text="k.comment">
                        </MarkdownContext>
                        <comments-operation :time="k.time" @showReply="showReply(i.id, k.id)" @deleteComment="
                          deleteComment(
                            k.id,
                            myInfo.id == i.touser || myInfo.id == k.fromuser,1
                          )
                          " :ip_format="k.ip_format" :showDelete="myInfo &&
                            (myInfo.id == k.fromuser || myInfo.is_admin)
                            " 
                            />
                      </v-col>
                    </v-row>
                  </span>
                </v-row>
              </div>
            </div>
          </div>
          <div class="text-center">
            <v-btn :prepend-icon="'mdi-unfold-' +
              (showed.indexOf(index) == -1 ? 'more' : 'less') +
              '-horizontal'
              " class="text--secondary px-auto sd" v-if="i.replynum > 2" color="primary" @click="foldReply(index)">
              {{ showed.indexOf(index) == -1 ? "展开" : "收起" }}
            </v-btn>
          </div>
        </div>
      </div>
      <div v-if="index % 4 == 0 && comment.ad && comment.ad[index / 4]" class="mt-2">
        <v-row no-gutters>
          <span style="flex: 0 0 5px"></span>
          <span style="flex: 0 0 10px" class="mt-3">
            <a :href="'#page=user&id=' + comment.ad[index / 4].author">
              <span :class="'avatar2 id' + comment.ad[(index / 4) % 4].author"></span>
              <v-avatar size="40" class="">
                <v-img cover :src="host.data +
                  '/static/internalapi/asset/' +
                  (comment.admap[comment.ad[(index / 4) % 4].author].head ||
                    '6e2b0b1056aaa08419fb69a3d7aa5727.png')
                  " />
              </v-avatar>
            </a>
          </span>
          <span style="flex: 0 0 10px"></span>
          <span style="flex: 0 0 calc(100% -60px)">
            <v-row no-gutters>
              <v-col cols="12">
                <a :href="'#page=work&id=' + comment.ad[(index / 4) % 4].id">
                  {{ comment.ad[(index / 4) % 4].name }}
                </a>
                <span style="color: #999">(作品推荐卡)</span>
                <span class="ml-2" style="color: #777">{{
                  date(comment.ad[(index / 4) % 4].update_time)
                }}</span>
              </v-col>
              <span style="flex: 0 0 200px">
                <a :href="'#page=work&id=' + comment.ad[(index / 4) % 4].id">
                  <v-img style="max-width: 200px" cover :src="host.data +
                    '/static/internalapi/asset/' +
                    (comment.ad[(index / 4) % 4].image ||
                      '6e2b0b1056aaa08419fb69a3d7aa5727.png')
                    " />
                </a>
              </span>
              <span style="flex: 0 0 10px"></span>
              <span style="flex: 0 0 200px">
                <span style="color: #999">
                  <v-icon size="16">mdi-eye</v-icon>
                  {{ comment.ad[(index / 4) % 4].look }}
                </span>
                <span style="color: #999" class="ml-1">
                  <v-icon size="16">mdi-heart</v-icon>
                  {{ comment.ad[(index / 4) % 4].like }} </span><br />
                <span style="color: #999">作者：</span><a :href="'#page=user&id=' + comment.ad[(index / 4) % 4].author">
                  {{
                    comment.admap[comment.ad[(index / 4) % 4].author].nickname
                  }}
                </a>
              </span>
            </v-row>
          </span>
        </v-row>
      </div>
    </div>
  </div>
  <div v-else-if="comment.comment">此用户暂时没有评论哦</div>
  <div v-else>
    <div class="text-center">
      <v-progress-circular color="red" indeterminate></v-progress-circular>
      正在加载中
    </div>
  </div>
</template>
<script setup>
import { defineProps, ref, defineEmits,computed } from "vue";
import SC2 from "./CommentInput.vue";
import MarkdownContext from "./MarkdownContext.vue";
import CommentsOperation from "./CommentsOperation.vue";
import SAvatar from "./SAvatar.vue";
var replyId = ref(-1),
  rid = ref(null),
  showed = ref([]);
const emit = defineEmits(["updateValue"]);
const props = defineProps(["comment", "host", "type", "id", "myInfo"]);
const headwears = ref({})
for (let i of props.comment.pendant) {
  if (!i.expirationTime || i.expirationTime < new Date / 1000)
    if (headwears.value[i.useid])
      headwears.value[i.useid].push(i.thumbId)
    else
      headwears.value[i.useid] = [i.thumbId]
}
const shouldShowDeleteButton = (commentItem) => {
    if (!props.myInfo) return false;
    return props.myInfo.is_admin || props.myInfo.id === commentItem.fromuser || props.myInfo.id === commentItem.touser || isStudioAdminLeader();
};

const shouldShowPinButton = (commentItem) => {
    if (!props.myInfo) return false;
    return props.myInfo.is_admin || props.myInfo.id === commentItem.touser || isStudioAdminLeader();
};

const isStudioAdminLeader = () => {
    if (props.type !== 2 || !props.myInfo) return false; // 仅工作室评论类型需要工作室权限检查
    if (!props.comment.studio || !props.comment.studio[props.myInfo.studio]) return false; // 检查工作室信息是否存在
    const studioInfo = props.comment.studio[props.myInfo.studio]; // 获取工作室信息
    return studioInfo && [1, 2, 3].includes(props.myInfo.studio_position); // 检查工作室职位
};


const reget = () => {
  emit('reget');
};

const showReply = (id, id2) => {
  if (!props.myInfo)
    return location.href = "#page=sign&url=" + btoa(location.href)
  replyId.value = id;
  rid.value = id2 || null;
};

const deleteComment = (commentToDelete) => { // 修改 deleteComment 函数，接收 commentItem
  if (
    confirm("你确定要删除此评论吗? ")
  )
    window
      .post("comment/delete", {
        id: commentToDelete.id, // 使用 commentToDelete.id
        type: props.type,
        p: 'commentdelete'
      })
      .then(() => {
        reget();
      });
};

const pinComment = (commentToPin) => { // 新增 pinComment 函数
  window
    .post("comment/pin", {
      comment_id: commentToPin.id, // 使用 commentToPin.id
      type: props.type,
    })
    .then(() => {
      reget();
    });
};

const unpinComment = (commentToUnpin) => { // 新增 unpinComment 函数
  window
    .post("comment/unpin", {
      comment_id: commentToUnpin.id, // 使用 commentToUnpin.id
      type: props.type,
    })
    .then(() => {
      reget();
    });
};


const date=window.convertTimestamp
function foldReply(index) {
  let i2 = showed.value.indexOf(index);
  if (i2 == -1) {
    showed.value.push(index);
  } else {
    showed.value.pop(i2);
  }
}
const sortedComments = computed(() => { // 新增 sortedComments 计算属性
    return [...props.comment.comment].sort((a, b) => {
        if (b.is_pinned && !a.is_pinned) {
            return 1; // b 置顶，排在前面
        } else if (!b.is_pinned && a.is_pinned) {
            return -1; // a 置顶，排在前面
        } else {
            return new Date(b.time) - new Date(a.time); // 否则按时间排序 (倒序)
        }
    });
});

</script>