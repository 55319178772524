<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="mx-auto" v-if="title">
        <v-card class="sd pt-2" style="margin-bottom: -17px; margin-top: -35px">
          <p class="text-subtitle-2 text-center" style="padding-bottom: 6px">
            {{ title }}
          </p>
        </v-card>
      </v-col>
      <v-tabs class="mx-3 sd" v-model="type">
        <v-tab> 作品 </v-tab>
        <v-tab> 用户 </v-tab>
        <v-tab> 工作室 </v-tab>
      </v-tabs>
      <v-col cols="12" class="mx-auto">
        <v-card class="sd py-2">
          <div class="mx-4 mt-4" style="display: flex; flex-wrap: wrap">
            <v-text-field
              label="作品名"
              onkeypress="13==event.keyCode && window.$('#search').click()"
              class="float-left ml-4"
              id="sname"
              v-if="type == 0"
              v-model="name"
              style="flex: 1 0 150px; margin: 4px"
            >
            </v-text-field>
            <v-text-field
              label="作者id"
              onkeypress="13==event.keyCode && window.$('#search').click()"
              class="float-left ml-4"
              id="sauthor"
              v-if="type == 0"
              v-model="author"
              style="flex: 1 0 150px; margin: 4px"
            >
            </v-text-field>
            <v-text-field
              label="用户名"
              onkeypress="13==event.keyCode && window.$('#search').click()"
              class="float-left ml-4"
              id="sname"
              v-if="type == 1"
              v-model="name"
              style="flex: 1 0 150px; margin: 4px"
            >
            </v-text-field>
            <v-text-field
              label="工作室名称"
              onkeypress="13==event.keyCode && window.$('#search').click()"
              class="float-left ml-4"
              id="sname"
              v-if="type == 2"
              v-model="name"
              style="flex: 1 0 150px; margin: 4px"
            >
            </v-text-field>
            <v-btn
              @click="search()"
              id="search"
              class="float-left ml-4"
              elevation="0"
              large
            >
              <v-icon> mdi-magnify </v-icon>
              搜索
            </v-btn>
            <v-select class="ml-4" v-model="select2" :items="s2[type]">
            </v-select>
          </div>
        </v-card>
      </v-col>
      <v-col class="mt-2" cols="12">
        <span class="ml-2" style="color: #888">
          为你找到 {{ res.num }} 个{{ ["作品", "用户", "工作室"][type] }}，耗时
          {{ time }}
          ms
        </span>
      </v-col>
      <v-col cols="12">
        <v-row v-if="type == 0">
          <v-col v-for="j in res.worklist" cols="6" sm="4" md="3" lg="2" :key="j">
            <work-card
              :work="j" :user="res.userlist[j.author.toString()][0]" :host="host"/>
          </v-col>
        </v-row>
        <v-row v-if="type == 1 && res.user">
          <v-col v-for="j in res.user" cols="6" sm="4" md="3" lg="2" :key="j">
            <user-card :user="j" :host="host" />
          </v-col>
        </v-row>
        <v-row v-if="type == 2 && res.studio">
          <v-col v-for="j in res.studio" cols="6" sm="4" md="3" lg="2" :key="j">
            <studio-card :studio="j" :host="host" />
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="!res.worklist && !res.user && !res.studio">
        <span class="mx-auto mt-4">
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
          正在载入数据……
        </span>
      </v-col>
    </v-row>
    <div class="text-center my-3" v-if="res.num > 0">
      <s-pagination
        v-model="page"
        :length="Math.ceil(res.num / 12)"
        :total-visible="7"
      />
    </div>
  </v-container>
</template>
<script setup>
import { defineProps, ref, watch } from "vue";
defineProps(["host", "myInfo"]);
import UserCard from "./UserCard";
import WorkCard from "./WorkCard";
import StudioCard from "./StudioCard";
import SPagination from "./SPagination";
var s2 = [
  [
    "最早创建",
    "最早发布",
    "最近更新",
    "最近发布",
    "最多点赞",
    "最多浏览",
    "最多收藏",
  ],
  ["最多金币", "最新注册", "最早注册"],
  ["最多成员", "最多作品", "最早创建", "最新创建"],
];
var getQueryString = window.getQueryString;
var name = ref(getQueryString("name")),
  author = ref(getQueryString("author")),
  type = ref(getQueryString("type")),
  s = ref(getQueryString("s") || ""),
  sid = ref(getQueryString("sid") || ""),
  fl = ref(getQueryString("fl") || ""),
  fan = ref(getQueryString("fan") || ""),
  follow = ref(getQueryString("follow") || ""),
  folder = ref(getQueryString("folder") || ""),
  title = ref(""),
  page = ref(getQueryString("p")),
  select2 = ref(s2[type.value][s]),
  res = ref({});
function search() {
  location.href = `#page=search&name=${name.value}&author=${author.value}&type=${type.value}&s=${s.value}&sid=${sid.value}&fl=${fl.value}&folder=${folder.value}&fan=${fan.value}&follow=${follow.value}&p=${page.value}`;
  reget();
}
watch([page,type], (old, _new) => {
  // console.log(old, _new);
  old != _new && search();
});
watch(select2, () => {
  s.value = s2[type.value].indexOf(select2.value);
  search();
});
function reget() {
  res.value && (res.value = {});
  window
    .post("search/", {
      name: name.value,
      author: author.value,
      type: type.value,
      s: s.value || 0,
      sid: sid.value,
      fl: fl.value || 0,
      fan: fan.value || 0,
      follow: follow.value || 0,
      page: page.value,
      folder: folder.value || 0,
    })
    .then((d) => {
      res.value = d.data;
    });
}
reget();
window.setTitle("搜索");

</script>